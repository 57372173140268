<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="!loadingfalg">
      <div class="manufacturconDetails">
        <div class="manufacturcontitle">
          <van-checkbox v-model="checked" @click="onchecked"
            >仅展示关注节点</van-checkbox
          >
        </div>
        <div class="manufacturcontbox">
          <div
            class="manufacturcontitem"
            :class="
              checked &&
              !item.checked &&
              item.children.lengrh != 0 &&
              !item.fiagValid
                ? 'chenfflagclass'
                : ''
            "
            v-for="(item, index) in treeData"
            :key="index"
          >
            <div>
              <div>
                <div v-if="(checked && item.fiagValid) || !checked">
                  <div class="title">
                    <span class="left">
                      <p>{{ index + 1 }} {{ item.sectionName }}</p>
                    </span>
                    <div class="right">
                      <p class="rightbotton" v-if="item.fiagValid">关注节点</p>
                      <p class="rightbotton" v-if="item.flagMilestone">
                        里程碑
                      </p>
                      <van-icon
                        name="arrow-up"
                        v-if="item.flag"
                        @click="onallfalg(item)"
                      />
                      <van-icon
                        name="arrow-down"
                        v-else
                        @click="onallfalg(item)"
                      />
                    </div>
                  </div>
                  <div class="onemanufactur" v-if="item.flag">
                    <div class="onemanufacturbox">
                      <p>
                        <span class="onemanufacturtitle">计划日期：</span
                        ><span
                          >{{ item.planStartdate }} 至
                          {{ item.planEnddate }}</span
                        >
                      </p>
                      <p v-if="page">
                        <span class="onemanufacturtitle"> 任务描述：</span
                        ><span>{{ item.taskdesc }}</span>
                      </p>
                      <p v-else>
                        <span class="onemanufacturtitle"> 工期：</span
                        ><span>{{ item.duration }}天</span>
                      </p>
                      <p>
                        <span class="onemanufacturtitle">备注：</span
                        ><span>{{ item.remark }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    (item.flagLevelval && item.checked && checked) ||
                      (item.flagLevelval && !checked)
                  "
                >
                  <div v-for="(item1, index1) in item.children" :key="index1">
                    <div v-if="(checked && item1.fiagValid) || !checked">
                      <div class="title">
                        <span class="left">
                          <p>{{ item1.levetitle }} {{ item1.sectionName }}</p>
                        </span>
                        <div class="right">
                          <p class="rightbotton" v-if="item1.fiagValid">
                            关注节点
                          </p>
                          <p class="rightbotton" v-if="item1.flagMilestone">
                            里程碑
                          </p>
                          <van-icon />
                        </div>
                      </div>
                      <div class="onemanufactur">
                        <div class="onemanufacturbox">
                          <p>
                            <span class="onemanufacturtitle">计划日期：</span
                            ><span
                              >{{ item1.planStartdate }} 至
                              {{ item1.planEnddate }}</span
                            >
                          </p>
                          <p v-if="page">
                            <span class="onemanufacturtitle"> 任务描述：</span
                            ><span>{{ item1.taskdesc }}</span>
                          </p>
                          <p v-else>
                            <span class="onemanufacturtitle"> 工期：</span
                            ><span>{{ item1.duration }}天</span>
                          </p>

                          <p>
                            <span class="onemanufacturtitle">备注：</span
                            ><span>{{ item1.remark }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="icon"
                  v-if="
                    (item.children.length != 0 && checked && item.fiagValid) ||
                      (item.children.length != 0 && !checked)
                  "
                >
                  <van-divider dashed :style="{ borderColor: '#000' }" />
                  <van-icon
                    name="arrow-up"
                    v-if="item.flagLevelval"
                    @click="onallfalgdown(item)"
                  />
                  <van-icon
                    name="arrow-down"
                    v-if="!item.flagLevelval"
                    @click="onallfalgdown(item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      treeData: [],
      activeNames: ["1"],
      files: [],
      loadingfalg: true,
      submitflag: false,
      show: true,
      title: "制造计划详情",
      loading: false,
      finished: true,
      isLoading: false,
      checked: false,
      treeDatachecklist: [],
    };
  },
  created() {
    this.procmanuplanInfo();
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    page() {
      return this.$route.query.page;
    },
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onallfalg(item) {
      item.flagLevelval = false;
      item.flag = !item.flag;
    },
    onallfalgdown(item) {
      item.flagLevelval = !item.flagLevelval;
      item.flag = true;
    },
    onchecked() {
      this.treeData.map((item) => {
        if (this.checked) {
          if (!item.fiagValid) {
            item.flagLevelval = true;
          } else {
            item.flagLevelval = false;
          }
        } else {
          item.flagLevelval = false;
        }
      });
      if (this.checked) {
        console.log(this.checked);
      } else {
        console.log(this.checked);
      }
    },
    async procmanuplanInfo() {
      let params = {
        id: this.id,
        type: this.$route.query.type,
      };
      let data = await this.$api.ec.procmanuplanInfo(params);
      console.log(data);
      if (data && data.length != 0) {
        let arr = this.arrayToTree(data.data);
        console.log(arr);
        console.log("tree", this.treeData);
        let arr1 = this.treeToArr(arr);
        console.log(arr1);
        arr1.map((item) => {
          delete item.children;
          return item;
        });
        console.log(arr1);
        let arr2 = arr1.filter((item) => {
          if (item.flagLevel == 1) {
            item.flag = true;
            item.flagLevelval = false;
            return item;
          }
        });
        console.log(arr2);
        arr2.map((item) => {
          item.children = [];
          arr1.map((item1) => {
            if (item.index == item1.index) {
              if (item1.flagLevel != 1) {
                item.children.push(item1);
              }
            }
          });
        });
        console.log(arr2);
        this.loadingfalg = false;
        this.treeData = arr2;
        this.treeData.map((item) => {
          if (item.children) {
            let flag = item.children.some((item) => {
              return item.fiagValid;
            });
            if (flag) {
              item.checked = true;
            } else {
              item.checked = false;
            }
          }
        });
      } else {
        this.loadingfalg = false;
      }
    },
    treeToArr(data, res = [], levetitle) {
      data.forEach((item, index) => {
        if (item.flagLevel != 1) {
          item.leve = index + 1;
          item.levetitle = `${levetitle}.${item.leve}`;
        } else {
          item.levetitle = index + 1;
        }
        res.push(item);
        if (item.children && item.children.length !== 0) {
          this.treeToArr(item.children, res, item.levetitle);
        }
      });
      return res;
    },
    arrayToTree(data) {
      const treeList = [];
      const map = {};

      data.forEach((item) => {
        if (!item.children) {
          item.children = [];
        }
        map[item.sectionId] = item;
      });
      data.forEach((item, index) => {
        const parent = map[item.sectionParentId];
        if (parent) {
          item.index = parent.index;
          parent.children.push(item);
        } else {
          item.index = index;
          treeList.push(item);
        }
      });
      return treeList;
    },

    sortArr(arr, str) {
      var _arr = [],
        _t = [],
        // 临时的变量
        _tmp;

      // 按照特定的参数将数组排序将具有相同值得排在一起
      arr = arr.sort(function(a, b) {
        var s = a[str],
          t = b[str];

        return s < t ? -1 : 1;
      });

      if (arr.length) {
        _tmp = arr[0][str];
      }
      // console.log( arr );
      // 将相同类别的对象添加到统一个数组
      for (var i in arr) {
        if (arr[i][str] === _tmp) {
          _t.push(arr[i]);
        } else {
          _tmp = arr[i][str];
          _arr.push(_t);
          _t = [arr[i]];
        }
      }
      // 将最后的内容推出新数组
      _arr.push(_t); //获取到所有整合数据
      console.log(_arr); //可以得到图三
      // 终极整合
      var integration = [];
      _arr.forEach((item) => {
        var eee = { name: "", data: [] };
        item.forEach((self) => {
          if (eee.name != self.fileCodename) {
            eee.name = self.sectionParentId;
            eee.data.push(self);
          } else {
            eee.data.push(self);
          }
        });
        integration.push(eee);
      });
      console.log(integration); //图二
      return integration;
    },
  },
};
</script>
<style lang="less" scoped>
.manufacturconDetails {
  .manufacturcontitle {
    height: 40px;
    background: white;
    padding-left: 20px;
    padding-top: 10px;
  }
  .manufacturcontbox {
    padding: 0 10px;
    .manufacturcontitem {
      width: 100%;
      //   height: 20px;
      background: white;
      border-radius: 5px;
      padding: 5px;
      margin-top: 10px;
      .title {
        height: 30px;
        padding: 0 6px;
        background: #eff6ff;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          font-weight: 700;
        }
        .right {
          .rightbotton {
            display: inline-block;
            background: #3d8ff5;
            padding: 0px 3px;
            margin-right: 9px;
            color: white;
            border-radius: 2px;
          }
        }
      }
      .onemanufactur {
        // height: 80px;
        // margin: 5px 10px;
        // border-bottom: 1px dashed #fff;
        .onemanufacturbox {
          margin-bottom: 10px;
          padding-left: 5px;
          padding-right: 5px;
        }
        padding-top: 10px;

        line-height: 25px;
        .onemanufacturtitle {
          color: #8f8f8f;
        }
      }
    }
  }
}
.chenfflagclass {
  padding: 0 !important;
}
/deep/.van-icon {
  width: 17px;
  height: 17px;
  margin-top: 2px;
}
.icon {
  width: 100%;
  text-align: center;
  color: #2d8aff;
}
</style>
