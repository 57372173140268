var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-search"},[_c('van-nav-bar',{attrs:{"title":_vm.title,"left-text":"","left-arrow":""},on:{"click-left":_vm.onClickLeft}})],1)]),(_vm.loadingfalg)?_c('div',{staticClass:"content contentbox loadingbox"},[_c('van-loading',{attrs:{"size":"24px","vertical":""}},[_vm._v("加载中...")])],1):_vm._e(),(!_vm.loadingfalg)?_c('div',{staticClass:"content contentbox"},[_c('div',{staticClass:"manufacturconDetails"},[_c('div',{staticClass:"manufacturcontitle"},[_c('van-checkbox',{on:{"click":_vm.onchecked},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("仅展示关注节点")])],1),_c('div',{staticClass:"manufacturcontbox"},_vm._l((_vm.treeData),function(item,index){return _c('div',{key:index,staticClass:"manufacturcontitem",class:_vm.checked &&
            !item.checked &&
            item.children.lengrh != 0 &&
            !item.fiagValid
              ? 'chenfflagclass'
              : ''},[_c('div',[_c('div',[((_vm.checked && item.fiagValid) || !_vm.checked)?_c('div',[_c('div',{staticClass:"title"},[_c('span',{staticClass:"left"},[_c('p',[_vm._v(_vm._s(index + 1)+" "+_vm._s(item.sectionName))])]),_c('div',{staticClass:"right"},[(item.fiagValid)?_c('p',{staticClass:"rightbotton"},[_vm._v("关注节点")]):_vm._e(),(item.flagMilestone)?_c('p',{staticClass:"rightbotton"},[_vm._v(" 里程碑 ")]):_vm._e(),(item.flag)?_c('van-icon',{attrs:{"name":"arrow-up"},on:{"click":function($event){return _vm.onallfalg(item)}}}):_c('van-icon',{attrs:{"name":"arrow-down"},on:{"click":function($event){return _vm.onallfalg(item)}}})],1)]),(item.flag)?_c('div',{staticClass:"onemanufactur"},[_c('div',{staticClass:"onemanufacturbox"},[_c('p',[_c('span',{staticClass:"onemanufacturtitle"},[_vm._v("计划日期：")]),_c('span',[_vm._v(_vm._s(item.planStartdate)+" 至 "+_vm._s(item.planEnddate))])]),(_vm.page)?_c('p',[_c('span',{staticClass:"onemanufacturtitle"},[_vm._v(" 任务描述：")]),_c('span',[_vm._v(_vm._s(item.taskdesc))])]):_c('p',[_c('span',{staticClass:"onemanufacturtitle"},[_vm._v(" 工期：")]),_c('span',[_vm._v(_vm._s(item.duration)+"天")])]),_c('p',[_c('span',{staticClass:"onemanufacturtitle"},[_vm._v("备注：")]),_c('span',[_vm._v(_vm._s(item.remark))])])])]):_vm._e()]):_vm._e(),(
                  (item.flagLevelval && item.checked && _vm.checked) ||
                    (item.flagLevelval && !_vm.checked)
                )?_c('div',_vm._l((item.children),function(item1,index1){return _c('div',{key:index1},[((_vm.checked && item1.fiagValid) || !_vm.checked)?_c('div',[_c('div',{staticClass:"title"},[_c('span',{staticClass:"left"},[_c('p',[_vm._v(_vm._s(item1.levetitle)+" "+_vm._s(item1.sectionName))])]),_c('div',{staticClass:"right"},[(item1.fiagValid)?_c('p',{staticClass:"rightbotton"},[_vm._v(" 关注节点 ")]):_vm._e(),(item1.flagMilestone)?_c('p',{staticClass:"rightbotton"},[_vm._v(" 里程碑 ")]):_vm._e(),_c('van-icon')],1)]),_c('div',{staticClass:"onemanufactur"},[_c('div',{staticClass:"onemanufacturbox"},[_c('p',[_c('span',{staticClass:"onemanufacturtitle"},[_vm._v("计划日期：")]),_c('span',[_vm._v(_vm._s(item1.planStartdate)+" 至 "+_vm._s(item1.planEnddate))])]),(_vm.page)?_c('p',[_c('span',{staticClass:"onemanufacturtitle"},[_vm._v(" 任务描述：")]),_c('span',[_vm._v(_vm._s(item1.taskdesc))])]):_c('p',[_c('span',{staticClass:"onemanufacturtitle"},[_vm._v(" 工期：")]),_c('span',[_vm._v(_vm._s(item1.duration)+"天")])]),_c('p',[_c('span',{staticClass:"onemanufacturtitle"},[_vm._v("备注：")]),_c('span',[_vm._v(_vm._s(item1.remark))])])])])]):_vm._e()])}),0):_vm._e(),(
                  (item.children.length != 0 && _vm.checked && item.fiagValid) ||
                    (item.children.length != 0 && !_vm.checked)
                )?_c('div',{staticClass:"icon"},[_c('van-divider',{style:({ borderColor: '#000' }),attrs:{"dashed":""}}),(item.flagLevelval)?_c('van-icon',{attrs:{"name":"arrow-up"},on:{"click":function($event){return _vm.onallfalgdown(item)}}}):_vm._e(),(!item.flagLevelval)?_c('van-icon',{attrs:{"name":"arrow-down"},on:{"click":function($event){return _vm.onallfalgdown(item)}}}):_vm._e()],1):_vm._e()])])])}),0)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }